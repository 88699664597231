import { createSlice } from '@reduxjs/toolkit'
import {
    SanctionMemberListRevenueBreakDown,
    SanctionPlanListRevenueBreakDown,
    SanctionRevenueBreakDownGrandTotal,
    SanctionRevenueBreakDown
} from './thunk'

export const initialState = {
    list: [],
    members: {
        list: [],
        loading: true
    },
    plans: {
        list: [],
        loading: true
    },
    grandTotal: {
        grand_total: '0.00',
        connected_accnt_amt: '0.00',
        master_accnt_amt: '0.00',
        loading: true
    },
    loading: true
}
const MemberShipRevenueBreakDownReducer = createSlice({
    name: '@membership/RevenueBreakDown',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //SanctionMemberListRevenueBreakDown
        builder.addCase(SanctionRevenueBreakDown.pending, (state) => {
            state.list = []
            state.loading = true
        })
        builder.addCase(SanctionRevenueBreakDown.fulfilled, (state, action) => {
            const { data } = action.payload
            state.list = data || []
            state.loading = false
        })
        builder.addCase(SanctionRevenueBreakDown.rejected, (state) => {
            state.list = []
            state.loading = false
        })
        // SanctionRevenueBreakDown
        // MemberListRevenueBreakDown
        builder.addCase(SanctionMemberListRevenueBreakDown.pending, (state) => {
            state.members.list = []
            state.members.loading = true
        })
        builder.addCase(SanctionMemberListRevenueBreakDown.fulfilled, (state, action) => {
            state.members.list = action.payload || []
            state.members.loading = false
        })
        builder.addCase(SanctionMemberListRevenueBreakDown.rejected, (state) => {
            state.members.list = []
            state.members.loading = false
        })

        // MemberListRevenueBreakDown
        builder.addCase(SanctionPlanListRevenueBreakDown.pending, (state) => {
            state.plans.list = []
            state.plans.loading = true
        })
        builder.addCase(SanctionPlanListRevenueBreakDown.fulfilled, (state, action) => {
            state.plans.list = action.payload || []
            state.plans.loading = false
        })
        builder.addCase(SanctionPlanListRevenueBreakDown.rejected, (state) => {
            state.plans.list = []
            state.plans.loading = false
        })

        // RevenueBreakDownGrandTotal
        builder.addCase(SanctionRevenueBreakDownGrandTotal.pending, (state) => {
            state.grandTotal.master_accnt_amt = '0.00'
            state.grandTotal.grand_total = '0.00'
            state.grandTotal.connected_accnt_amt = '0.00'
            state.grandTotal.loading = true
        })
        builder.addCase(SanctionRevenueBreakDownGrandTotal.fulfilled, (state, { payload }) => {
            state.grandTotal.master_accnt_amt = payload.master_accnt_amt
            state.grandTotal.grand_total = payload.grand_total
            state.grandTotal.connected_accnt_amt = payload.connected_accnt_amt
            state.grandTotal.loading = false
        })
        builder.addCase(SanctionRevenueBreakDownGrandTotal.rejected, (state) => {
            state.grandTotal.master_accnt_amt = '0.00'
            state.grandTotal.grand_total = '0.00'
            state.grandTotal.connected_accnt_amt = '0.00'
            state.members.list = []
            state.grandTotal.loading = false
        })
    }
})
export default MemberShipRevenueBreakDownReducer.reducer
