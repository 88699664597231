// Admin
export const USER_LOGIN_URL = '/login'
export const USER_LOAD_URL = '/profile/admin/profile_details'
export const CLUB_LOAD_URL = '/profile/club/profile_details'
export const USER_LOGOUT_URL = '/logout'
export const USER_PROFILE_UPDATE_URL = '/clubs/account-setting/edit-profile'
export const USER_PASSWORD_CHANGE_URL = '/clubs/account-setting/change_password'
export const USER_FORGOT_PASSWORD_URL = '/password/forgot_password'
export const REFRESH_TOKEN_URL = '/token'
export const MEMBER_EMAIL_ALREADY_EXISTS_URL = '/user-member/register/is_email_exist'
export const MEMBER_RESEND_VERIFY_EMAIL_URL = '/user-member/user_activation/resend_verify_link'

// Club Plans

export const CLUB_PLANS_LIST = '/clubs/membership-fee-config/membership-plan'
export const PLAN_DETAIL_URL = '/clubs/membership-fee-config/admin/one'
export const ADD_PLAN_PAYMENT = '/clubs/club-nembership-club/club/create-plan-order'
export const SUCCESS_PLAN_PAYMENT = '/clubs/club-nembership-club/club/process-order'
export const CLUB_GRAND_TOTAL_URL =
    '/clubs/club-nembership/admin/purchased-membership-by-club/grand-total'
export const PLAN_PAYMENT_HISTORY_MEMBER =
    '/clubs/club-nembership-club/club/purchased-membership-by-club'

/// Plans
export const PLAN_LIST_URL = '/clubs/club-nembership/admin/purchased-membership-by-club'
export const PLAN_CREATE_URL = '/clubs/club-nembership/admin/add-membership-to-club'
export const MEMBERSHIP_PLAN_LIST = '/clubs/membership-fee-config/membership-plan'

//Membership ID Configuration

export const MEMBERSHIP_ID_CONFIGURATION_LIST = '/clubs/membership-id-configuration/club/all'
export const MEMBERSHIP_ID_CONFIGURATION_ONE = '/clubs/membership-id-configuration/club/one'
export const MEMBERSHIP_ID_CONFIGURATION_CREATE = '/clubs/membership-id-configuration/club/create'
export const MEMBERSHIP_ID_CONFIGURATION_UPDATE = '/clubs/membership-id-configuration/club/update'
export const MEMBERSHIP_GENERATED_ID_LIST = '/order/membership-id-generation/test-id'

// Country
export const COUNTRY_LIST_URL = '/address/country/all'

// State
export const STATE_LIST_URL = '/address/state/all'
// Membership Age Classification
export const AGE_CLASSIFICATION_LIST_URL = '/clubs/membership-age-classification/club/all'
export const AGE_CLASSIFICATION_ONE_URL = '/clubs/membership-age-classification/club/one'
export const AGE_CLASSIFICATION_CREATE_URL = '/clubs/membership-age-classification/club/create'
export const AGE_CLASSIFICATION_UPDATE_URL = '/clubs/membership-age-classification/club/update'
export const AGE_CLASSIFICATION_DELETE_URL = '/clubs/membership-age-classification/club/delete'

// Membership membership-plans List
export const MEMBERSHIP_PLANS_LIST = '/clubs/membership-plans/club/all'
export const MEMBERSHIP_PLANS_CREATE = '/clubs/membership-plans/club/create'
export const MEMBERSHIP_PLANS_ONE = '/clubs/membership-plans/club/one'
export const MEMBERSHIP_PLANS_UPDATE = '/clubs/membership-plans/club/update'
export const MEMBERSHIP_PLANS_DELETE = '/clubs/membership-plans/club/delete'
export const MEMBERSHIP_PLANS_PUBLISH_STATUS = '/clubs/membership-plans/club/update-publish'
export const MEMBERSHIP_PLANS_LIST_FOR_MEMBER_CREATE =
    '/clubs/membership-plans/club-membership/club/all'
export const MEMBERSHIP_PLANS_ONE_MEMBER = '/clubs/membership-plans/club-membership/club/one'

// Membership card Template
export const MEMBERSHIP_CARD_TEMPLATE_CREATE_URL = '/clubs/membership-card-template/create'
export const MEMBERSHIP_CARD_TEMPLATE_LIST_URL = '/clubs/membership-card-template/all'
export const MEMBERSHIP_CARD_TEMPLATE_UPDATE_URL = '/clubs/membership-card-template/update'
export const MEMBERSHIP_CARD_TEMPLATE_DELETE_URL = '/clubs/membership-card-template/delete'

// Membership card Template
export const MEMBERSHIP_RENEW_LETTER_TEMPLATE_CREATE_URL =
    '/clubs/membership-renew-letter-template/create'
export const MEMBERSHIP_RENEW_LETTER_TEMPLATE_LIST_URL =
    '/clubs/membership-renew-letter-template/all'
export const MEMBERSHIP_RENEW_LETTER_TEMPLATE_UPDATE_URL =
    '/clubs/membership-renew-letter-template/update'
export const MEMBERSHIP_RENEW_LETTER_TEMPLATE_DELETE_URL =
    '/clubs/membership-renew-letter-template/delete'

// Mebership Letter-Template
export const LETTER_TEMPLATE_LIST = '/clubs/membership-letter-template/all'
export const LETTER_TEMPLATE_CREATE = '/clubs/membership-letter-template/create'
// export const LETTER_TEMPLATE_ONE = '/clubs/membership-plans/club/one'
export const LETTER_TEMPLATE_UPDATE = '/clubs/membership-letter-template/update'
export const LETTER_TEMPLATE_DELETE = '/clubs/membership-letter-template/delete'

// Membership Processing fees
export const MEMBERSHIP_PROCESSING_FEES_CREATE_URL = '/clubs/membership-processing-fee/club/create'
export const MEMBERSHIP_PROCESSING_FEES_LIST_URL = '/clubs/membership-processing-fee/club/all'
export const MEMBERSHIP_PROCESSING_FEES_UPDATE_URL = '/clubs/membership-processing-fee/club/update'
// export const MEMBERSHIP_PROCESSING_FEES_DELETE_URL =
//     '/clubs/membership-renew-letter-template/delete'
// Member Users List
export const CLUB_MEMBERS_LIST = '/clubs/user-member'
export const CLUB_MEMBERS_ONE = '/clubs/user-member/one'
export const CLUB_MEMBERS_CREATE = '/clubs/user-member'
export const CLUB_ALIAS_MEMBERS_CREATE = '/clubs/user-member/create-alias-user'
export const CLUB_MEMBERS_UPDATE = '/clubs/user-member'
export const CLUB_MEMBERS_DELETE = '/clubs/user-member'

export const CLUB_MEMBERS_LIST_FOR_MEMBER_CREATE = '/clubs/user-member/club-membership/user-list'

// Member Users List
export const CLUB_SUB_MEMBER_LIST = '/clubs/user-member/subuser'
export const CLUB_SUB_MEMBER_ONE = '/clubs/user-member/subuser/one'
export const CLUB_SUB_MEMBER_CREATE = '/clubs/user-member/subuser'
export const CLUB_SUB_MEMBER_UPDATE = '/user-member/subuser'
export const CLUB_SUB_MEMBER_DELETE = '/clubs/user-member/subuser'

export const CLUB_SUB_MEMBER_LIST_FOR_MEMBER =
    '/clubs/user-member/subuser/club-membership/subuser-list'

// Membership List
export const MEMBERSHIP_LIST_TYPE_LIST = '/clubs/membership-plans/club/all'
export const MEMBER_USER_LIST = '/clubs/user-member'
//
export const CONNECT_ACCOUNT = '/payment/save-connected-accnt-info-club'
export const CONNECT_ACCOUNT_DASHBOARD_URL = '/payment/stripe-account-dashboard-link-club'

//
export const MEMBERSHIP_PROCESSING_FEES_LIST_ADMIN_URL = '/clubs/membership/fee-config/all'
export const MEMBERSHIP_PURCHASE_PAYMENT_INTENT = '/clubs/order/create-payment-intent'
export const MEMBERSHIP_PURCHASE_PAYMENT_SUCCESS = '/clubs/order/process-order'
export const MEMBERSHIP_ALL_STATUS = '/clubs/order/count-status'
export const MEMBERSHIP_LIST = '/clubs/order/membership-order-user-list'
export const MEMBERSHIP_LIST_OLD = '/clubs/order/membership-order'
export const SUB_MEMBER_MEMBERSHIP_LIST = '/clubs/order/membership-order-subuser-list'
export const PLAN_LIST_BY_MEMBER = '/clubs/order/membership-order'

// Sweepstake
export const GET_ALL_SWEEPSTAKE_POINTS_URL = '/sweepstake-points-club/all'
export const GET_SWEEPSTAKE_POINT_SINGLE_URL = '/sweepstake-points-club/one'
export const CREATE_SWEEPSTAKE_POINT_URL = '/sweepstake-points-club/create'
export const UPDATE_SWEEPSTAKE_POINT_URL = '/sweepstake-points-club/update'
export const DELETE_SWEEPSTAKE_POINT_URL = '/sweepstake-points-club/delete'
export const GET_ALL_SWEEPSTAKE_PACING_URL = '/sweepstake-points-club/placing_list'

export const GET_SWEEPSTAKE_MEMBER_SINGLE_URL = '/'
export const CREATE_SWEEPSTAKE_MEMBER_URL =
    '/clubs/user-member/club-membership/sweepstake-member-list/create'
export const UPDATE_SWEEPSTAKE_MEMBER_URL = '/'
export const GET_SWEEPSTAKE_MEMBER_LIST_FOR_FORM =
    '/clubs/user-member/club-membership/sweepstake-member-list'
export const GET_SWEEPSTAKE_MEMBER_LIST =
    '/clubs/user-member/club-membership/sweepstake-member-list'

export const GET_SWEEPSTAKE_SHOW_SETUP_SINGLE_URL = '/clubs/show-list/club/one'
export const CREATE_SWEEPSTAKE_SHOW_SETUP_URL = '/clubs/show-list/club/create'
export const UPDATE_SWEEPSTAKE_SHOW_SETUP_URL = '/clubs/show-list/club/update'
export const GET_SWEEPSTAKE_SHOW_SETUP_LIST_URL = '/clubs/show-list/club/all'
export const DELETE_SWEEPSTAKE_SHOW_SETUP_URL = '/clubs/show-list/club/delete'

export const GET_SWEEPSTAKE_JUDGE_SINGLE_URL = '/clubs/show-list-judge/club/one'
export const CREATE_SWEEPSTAKE_JUDGE_URL = '/clubs/show-list-judge/club/create'
export const UPDATE_SWEEPSTAKE_JUDGE_URL = '/clubs/show-list-judge/club/update'
export const GET_SWEEPSTAKE_JUDGE_LIST_URL = '/clubs/show-list-judge/club/all'
export const DELETE_SWEEPSTAKE_JUDGE_URL = '/clubs/show-list-judge/club/delete'

export const GET_ALL_SWEEPSTAKE_ALREADY_ADDED_MEMBER =
    '/clubs/user-member/club-membership/sweepstake-member-list-by-id'
export const DELETE_SWEEPSTAKE_EXHIBITOR_URL =
    '/clubs/user-member/club-membership/sweepstake-member-list/delete'

// Setting
export const GET_ALL_BREED_CATEGORY = '/breed/category/all'
export const GET_ALL_BREED = '/shows/secretary/breeds'
export const SETTING_CREATE_CLUB = '/clubs/setting-club-breed/create'
export const SETTING_DELETE_CLUB = '/'
export const SETTING_GET_ALL_CLUB = '/clubs/setting-club-breed/all'
export const SAVE_CLUB_TYPE_URL = '/clubs/setting-club-breed/set-type'
export const GET_CLUB_TYPE_URL = '/clubs/setting-club-breed/list/set-type'

// Control Sheet
export const GET_CONTROL_SHEET_BREED_DETAIL = '/breed-setup/classification/club/breed-hierarchy'
export const GET_ALL_SWEEPSTAKE_PACING_FOR_CONTROL_SHEET_URL =
    '/read-config-club/admin/placing_list'
export const GET_ALL_BREED_CONTROL_SHEET = '/breed/club/all'
export const SAVE_CONTROL_SHEET_PLACING = '/clubs/details-by-breed-report/club/points'
export const SAVE_CONTROL_SHEET_EXH_LIST_BY_POINTS_PLACING =
    '/clubs/exh-list-by-breed-report/club/points'
export const GET_CONTROL_SHEET_EXH_LIST_BY_POINTS_PLACING =
    '/clubs/exh-list-by-breed-report/club/get-points'
export const GET_CONTROL_SHEET_PLACING = '/clubs/details-by-breed-report/club/get-points'
export const GET_CONTROL_SHEET_EXH_LIST_BY_POINTS =
    '/clubs/details-by-breed-report/club/get-points-exh-by-breed'
export const RESET_CONTROL_SHEET_DETAIL_BY_BREED =
    '/clubs/details-by-breed-report/club/reset-points'
export const SAVE_CONTROL_SHEET_PLACING_QUALITY_POINTS = '/clubs/quality-result-report/club/points'

export const GET_CONTROL_SHEET_PLACING_QUALITY_POINTS =
    '/clubs/quality-result-report/club/get-points'

export const SAVE_CONTROL_SHEET_FUR_RESULT = '/clubs/fur-report/club/points'
export const GET_CONTROL_SHEET_FUR_RESULT = '/clubs/fur-report/club/get-points'
export const SAVE_CONTROL_SHEET_COMPLETE_EXH_LIST_WITH_RESULT =
    '/clubs/complete-exh-list-report/club/points'
export const GET_CONTROL_SHEET_COMPLETE_EXH_LIST_WITH_RESULT =
    '/clubs/complete-exh-list-report/club/get-points'
export const GET_DETAILS_BY_BREED_COMPLETE_EXHIBITOR_LIST_POINTS =
    '/clubs/details-by-breed-report/club/get-counts-total-exh'

// Contest

export const SAVE_CONTEST_URL = '/clubs/contest/create'
export const GET_ALL_CONTEST_URL = '/clubs/contest/all'
export const DELETE_CONTEST_URL = '/clubs/contest/delete'

export const SAVE_REPORT_TYPE = '/clubs/account-setting/report-type'
export const GET_OVERALL_REPORT_URL = '/clubs/contest-report/club-report/overall-contest-report'
export const GET_CONTEST_ONE_URL = 'clubs/contest/findsingle/contest'
export const GET_CONTEST_BREED_DETAIL =
    '/clubs/contest-report/club-report/overall-contest-report-vg'

// NewsLetter
export const SAVE_NEWS_LETTER_URL = '/clubs/newsletter-report/club/points'
export const GET_NEWS_LETTER_URL = '/clubs/newsletter-report/club/get-points'

// Membership list
export const GET_ALL_MEMBERSHIP_REMINDER_LOG = '/user-reminder-log/reminder-list/log'
export const GET_ALL_MEMBERSHIP_REMINDER_LIST_LOG = '/user-reminder-log/reminder-list'
export const GET_ALL_MEMBERSHIP_REMINDER_USER_LIST = '/user-reminder-log/user-reminder-list'
//
export const MEMBERSHIP_REMINDER_TEMPLATE_LIST = '/clubs/membership-reminder-template/all'
export const MEMBERSHIP_REMINDER_TEMPLATE_CREATE = '/clubs/membership-reminder-template/create'
// export const LETTER_TEMPLATE_ONE = '/clubs/membership-plans/club/one'
export const MEMBERSHIP_REMINDER_TEMPLATE_UPDATE = '/clubs/membership-reminder-template/update'
export const MEMBERSHIP_REMINDER_TEMPLATE_DELETE = '/clubs/membership-reminder-template/delete'

export const MEMBERSHIP_REMINDER_SETUP_LIST = '/clubs/membership-reminder/all'
export const MEMBERSHIP_REMINDER_SETUP_CREATE = '/clubs/membership-reminder/create'
export const MEMBERSHIP_REMINDER_SETUP_ONE = '/clubs/membership-reminder/one'
export const MEMBERSHIP_REMINDER_SETUP_UPDATE = '/clubs/membership-reminder/update'
export const MEMBERSHIP_REMINDER_SETUP_DELETE = '/clubs/membership-reminder/delete'

//Sanction ID Configuration

export const SANCTION_ID_CONFIGURATION_LIST = '/clubs/sanction-id-configuration/club/all'
export const SANCTION_ID_CONFIGURATION_ONE = '/clubs/sanction-id-configuration/club/one'
export const SANCTION_ID_CONFIGURATION_CREATE = '/clubs/sanction-id-configuration/club/create'
export const SANCTION_ID_CONFIGURATION_UPDATE = '/clubs/sanction-id-configuration/club/update'

// Sanction Show Classification

export const SHOW_CLASSIFICATION_LIST_URL = '/clubs/sanction-show-classification/club/all'
export const SHOW_CLASSIFICATION_ONE_URL = '/clubs/sanction-show-classification/club/one'
export const SHOW_CLASSIFICATION_CREATE_URL = '/clubs/sanction-show-classification/club/create'
export const SHOW_CLASSIFICATION_UPDATE_URL = '/clubs/sanction-show-classification/club/update'
export const SHOW_CLASSIFICATION_DELETE_URL = '/clubs/sanction-show-classification/club/delete'

// Sanction Letter Template
export const SANCTION_LETTER_TEMPLATE_LIST = '/clubs/sanction-letter-template/all'
export const SANCTION_LETTER_TEMPLATE_CREATE = '/clubs/sanction-letter-template/create'
// export const LETTER_TEMPLATE_ONE = '/clubs/sanction-plans/club/one'
export const SANCTION_LETTER_TEMPLATE_UPDATE = '/clubs/sanction-letter-template/update'
export const SANCTION_LETTER_TEMPLATE_DELETE = '/clubs/sanction-letter-template/delete'

// Sanction card Template
export const SANCTION_CARD_TEMPLATE_CREATE_URL = '/clubs/sanction-card-template/create'
export const SANCTION_CARD_TEMPLATE_LIST_URL = '/clubs/sanction-card-template/all'
export const SANCTION_CARD_TEMPLATE_UPDATE_URL = '/clubs/sanction-card-template/update'
export const SANCTION_CARD_TEMPLATE_DELETE_URL = '/clubs/sanction-card-template/delete'

// Sanction Processing Fees
export const SANCTION_PROCESSING_FEES_CREATE_URL = '/clubs/sanction-processing-fee/club/create'
export const SANCTION_PROCESSING_FEES_LIST_URL = '/clubs/sanction-processing-fee/club/all'
export const SANCTION_PROCESSING_FEES_UPDATE_URL = '/clubs/sanction-processing-fee/club/update'

// Sanction Reminder
export const GET_ALL_SANCTION_REMINDER_LOG = '/sanction/user-reminder-log/reminder-list/log'
export const GET_ALL_SANCTION_REMINDER_LIST_LOG = '/sanction-as/user-reminder-log/reminder-list'
export const GET_ALL_SANCTION_REMINDER_USER_LIST =
    '/sanction-as/user-reminder-log/user-reminder-list'
//
export const SANCTION_REMINDER_TEMPLATE_LIST = '/clubs/sanction-reminder-template/all'
export const SANCTION_REMINDER_TEMPLATE_CREATE = '/clubs/sanction-reminder-template/create'
// export const LETTER_TEMPLATE_ONE = '/clubs/sanction-plans/club/one'
export const SANCTION_REMINDER_TEMPLATE_UPDATE = '/clubs/sanction-reminder-template/update'
export const SANCTION_REMINDER_TEMPLATE_DELETE = '/clubs/sanction-reminder-template/delete'

export const SANCTION_REMINDER_SETUP_LIST = '/clubs/sanction-reminder/all'
export const SANCTION_REMINDER_SETUP_CREATE = '/clubs/sanction-reminder/create'
export const SANCTION_REMINDER_SETUP_ONE = '/clubs/sanction-reminder/one'
export const SANCTION_REMINDER_SETUP_UPDATE = '/clubs/sanction-reminder/update'
export const SANCTION_REMINDER_SETUP_DELETE = '/clubs/sanction-reminder/delete'

// Sanction Show Classification

export const SANCTION_PLAN_LIST_URL = '/clubs/sanction-plan/club/all'
export const SANCTION_PLAN_ONE_URL = '/clubs/sanction-plan/club/one'
export const SANCTION_PLAN_CREATE_URL = '/clubs/sanction-plan/club/create'
export const SANCTION_PLAN_UPDATE_URL = '/clubs/sanction-plan/club/update'
export const SANCTION_PLAN_DELETE_URL = '/clubs/sanction-plan/club/delete'
export const SANCTION_PLANS_PUBLISH_STATUS = '/clubs/sanction-plan/club/update-publish'

export const CLUB_SANCTION_PAYMENT_INTENT_CREATE = '/club/sanction-order/create-payment-intent'
export const CLUB_SANCTION_PAYMENT_SUCCESS = '/club/sanction-order/process-order'
export const CLUB_SANCTION_ALL_LIST = '/club/sanction-order/sanction-order'
export const ADMIN_SANCTION_PROCESSING_FEE = '/clubs/sanction/fee-config/all'

export const SANCTION_LIST_GRAND_TOTAL_URL = '/club/sanction-order/sanction-order/grand-total'

export const CLERK_LIST_URL = '/club-clerk/clerk'
export const CLERK_ONE_URL = '/club-clerk/clerk'
export const CLERK_CREATE_URL = '/club-clerk/clerk'
export const CLERK_UPDATE_URL = '/club-clerk/clerk'
export const CLERK_DELETE_URL = '/club-clerk/clerk'

// News Letter
export const NEWS_LETTER_CREATE_URL = '/news-letter-documents/upload'
export const NEWS_LETTER_LIST_URL = '/news-letter-documents/document'
export const NEWS_LETTER_UPDATE_URL = '/news-letter-documents/update-doc'
export const NEWS_LETTER_GET_ONE_URL = '/news-letter-documents/document'

//
export const MEMBERSHIP_LIST_EXPORT_TO_CSV =
    '/order/club-membership-plan/club/membership-order-listing-csv'
// https://time2enter-back-front-ukew5b67hq-uc.a.run.app/api/v1/order/club-membership-plan/club/membership-order-listing-csv?params[_sort]=mmo.created_on&params[_order]=DESC

export const CHANGE_NEWS_LETTER_STATUS = '/news-letter-documents/add-user-permission-newsletter'
export const GET_ALL_REVENUE_BREAK_DOWN_LIST =
    '/clubs/order/club/membership-order/membership-revenue-list'
export const GET_ALL_MEMBER_LIST_REVENUE_BREAK_DOWN_LIST =
    '/clubs/order/club/membership-order/user-list'
export const PLAN_LIST_REVENUE_BREAK_DOWN_LIST = '/clubs/order/club/membership-order/plan-list'
export const REVENUE_BREAK_DOWN_GRAND_TOTAL = '/clubs/order/club/membership-order/grand-total'


//

export const GET_ALL_SANCTION_REVENUE_BREAK_DOWN_LIST =
    '/club/sanction-order/club/sanction-order/sanction-revenue-list'
export const GET_ALL_SANCTION_MEMBER_LIST_REVENUE_BREAK_DOWN_LIST =
    '/club/sanction-order/club/sanction-order/user-list'
export const SANCTION_PLAN_LIST_REVENUE_BREAK_DOWN_LIST = '/club/sanction-order/club/sanction-order/plan-list'
export const SANCTION_REVENUE_BREAK_DOWN_GRAND_TOTAL = '/club/sanction-order/club/sanction-order/grand-total'