import { useCallback, useEffect, useRef } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { Link, useLocation } from 'react-router-dom'

const ClubSidebarContent = () => {
    const ref = useRef()
    const activateParentDropdown = useCallback((item) => {
        item.classList.add('active')
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]

        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show')
        }

        if (parent) {
            parent.classList.add('mm-active') // li
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add('mm-show') // ul tag
                parent2.classList.add('mm-active') // li
                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add('mm-active') // li
                    parent3.classList.add('mm-active') // li
                    // parent3.childNodes[0].classList.add('mm-active') //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add('mm-show') // ul
                        parent4.classList.add('mm-active') // li
                        // parent4.childNodes[0].classList.add('mm-active')
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add('mm-show') // li
                            parent5.classList.add('mm-active') // li
                            // parent5.childNodes[0].classList.add('mm-active') // a tag
                            const parent6 = parent5.parentElement

                            if (parent6) {
                                parent6.classList.add('mm-show') // li
                                parent6.classList.add('mm-active') // li

                                // parent6.childNodes[0].classList.add('mm-active') // a tag
                                const parent7 = parent6.parentElement
                                if (parent7) {
                                    parent7.classList.add('mm-show') // li
                                    parent7.classList.add('mm-active') // li
                                    parent7.childNodes[0].classList.add('mm-active') // a tag
                                }
                            }
                        }
                    }
                }
            }
            scrollElement(item)
            return false
        }
        scrollElement(item)
        return false
    }, [])

    const removeActivation = (items) => {
        for (let i = 0; i < items.length; ++i) {
            const item = items[i]
            const parent = items[i].parentElement

            if (item && item.classList.contains('active')) {
                item.classList.remove('active')
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null
                if (parent2El && parent2El.id !== 'side-menu') {
                    parent2El.classList.remove('mm-show')
                }

                parent.classList.remove('mm-active')
                const parent2 = parent.parentElement

                if (parent2) {
                    parent2.classList.remove('mm-show')

                    const parent3 = parent2.parentElement
                    if (parent3) {
                        parent3.classList.remove('mm-active') // li
                        parent3.childNodes[0].classList.remove('mm-active')

                        const parent4 = parent3.parentElement // ul
                        if (parent4) {
                            parent4.classList.remove('mm-show') // ul
                            const parent5 = parent4.parentElement
                            if (parent5) {
                                parent5.classList.remove('mm-show') // li
                                parent5.childNodes[0].classList.remove('mm-active') // a tag
                                const parent6 = parent5.parentElement

                                if (parent6) {
                                    parent6.classList.remove('mm-show') // li
                                    parent6.childNodes[0].classList.remove('mm-active') // a tag
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const path = useLocation()
    const activeMenu = useCallback(() => {
        let pathName = path.pathname.split('/')

        pathName = `/${pathName[1]}${pathName[2] ? `/${pathName[2]}` : ''}`
        let matchingMenuItem = null
        const ul = document.getElementById('side-menu')
        const items = ul.getElementsByTagName('a')

        removeActivation(items)

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    }, [path.pathname, activateParentDropdown])

    useEffect(() => {
        ref.current.recalculate()
    }, [])

    useEffect(() => {
        new MetisMenu('#side-menu')
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        activeMenu()
    }, [activeMenu])

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }
    return (
        <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
            <div id='sidebar-menu'>
                <ul className='metismenu list-unstyled' id='side-menu'>
                    <li>
                        <Link to='/dashboard' className='waves-effect m-0'>
                            <i className='bx bx-home-alt fs-box' />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to='/dashboard/setting' className='waves-effect m-0'>
                            <i className='bx bx-cog fs-box' />
                            <span>Setting</span>
                        </Link>
                    </li> */}

                    <li>
                        <Link
                            to='/#'
                            className='has-arrow  waves-effect m-0'
                            title='Club Management'
                        >
                            <i className='bx bx-cog fs-box' />
                            <span>Setting</span>
                        </Link>
                        <ul className='sub-menu'>
                            <li>
                                <Link
                                    to='/dashboard/setting'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx bx bx-barcode' />
                                    <span>Club Selection</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/report-type'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx  bx-receipt' />
                                    <span>Report Type</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/contest'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx bx-receipt' />
                                    <span>{`Contest's`}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to='/dashboard/plans' className='waves-effect m-0'>
                            <i className='bx bx-money fs-box' />
                            <span>Purchase Plan</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/dashboard/club_plan_purchased' className='waves-effect m-0'>
                            <i className='bx bx-dollar-circle fs-box' />
                            <span>Club Purchased Plans </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to='/#'
                            className='has-arrow  waves-effect m-0'
                            title='Club Management'
                        >
                            <i className='bx bx-group fs-box' />
                            <span>Membership</span>
                        </Link>
                        <ul className='sub-menu member-sub'>
                            <li>
                                <Link
                                    to='/dashboard/membership-id-configuration'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx bxs-id-card' />
                                    <span>ID Configuration</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/age_classification'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-sitemap' />
                                    <span>Age Classification</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/membership_plans'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-dollar' />
                                    <span>Membership Plans</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/membership-list'
                                    className='waves-effect m-0 gr-active '
                                >
                                    <i className='bx bx-list-ol' />
                                    <span>Membership List</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/letter_template'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-file' />
                                    <span>Letter Template</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link
                                    to='/dashboard/renew-letter-template'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-wallet' />
                                    <span>Renew Letter Template</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link
                                    to='/dashboard/membership_card_template'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-news' />
                                    <span>Membership Card Template</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/news-letter'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-news' />
                                    <span>Newsletter</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/processing_fees'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-money' />
                                    <span>Processing Fees</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/member_list'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx bx-group' />
                                    <span>Member / Alias List</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/'
                                    className='has-arrow waves-effect m-0 sub-child'
                                    title='Membership Reminder Setup'
                                >
                                    <i className='bx bx-alarm' />
                                    <span>Membership Reminder </span>
                                </Link>
                                <ul className='sub-menu'>
                                    <li className=''>
                                        <Link
                                            to='/dashboard/membership-reminder-setup'
                                            className='m-0 gr-active sub-child'
                                            title='Reminder Setup'
                                        >
                                            <span>Reminder Setup</span>
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link
                                            to='/dashboard/membership-reminder-template'
                                            className='m-0 gr-active sub-child'
                                            title='Reminder Template'
                                        >
                                            <span>Reminder Template</span>
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link
                                            to='/dashboard/membership-reminder-log'
                                            className='m-0 gr-active sub-child'
                                            title='Reminder log'
                                        >
                                            <span>Reminder Log</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/membership-revenue-breakdown'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx bx-money' />
                                    <span>Revenue Breakdown</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link
                                    to='/dashboard/membership-report'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-book' />
                                    <span>Membership Report</span>
                                </Link>
                            </li> */}
                        </ul>
                    </li>

                    <li>
                        <Link to='/#' className='has-arrow waves-effect m-0' title='Sweepstakes'>
                            <i className='bx bx-bar-chart-square fs-box' />
                            <span>Sweepstakes</span>
                        </Link>
                        <ul className='sub-menu'>
                            <li>
                                <Link
                                    to='/'
                                    className='has-arrow waves-effect m-0 sub-child'
                                    title='Sweepstakes Points'
                                >
                                    <i className='bx bx-highlight' />
                                    <span>Sweepstakes Points</span>
                                </Link>
                                <ul className='sub-menu'>
                                    <li className=''>
                                        <Link
                                            to='/'
                                            className='has-arrow m-0 gr-active sub-child'
                                            title='Class-sweepstake'
                                        >
                                            <span>Class</span>
                                        </Link>
                                        <ul className='sub-menu'>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-class-non-national'
                                                    className='waves-effect m-0 no-active'
                                                >
                                                    <span>Non-National</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-class-national'
                                                    className='waves-effect m-0 no-active'
                                                    // style={{
                                                    //     background: 'red !important'
                                                    // }}
                                                >
                                                    <span>National</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to='/'
                                            className='has-arrow waves-effect m-0 gr-active sub-child'
                                            title='Class-sweepstake'
                                        >
                                            <span>Fur</span>
                                        </Link>
                                        <ul className='sub-menu'>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-fur-non-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>Non-National</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-fur-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>National</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to='/'
                                            className='has-arrow waves-effect m-0 gr-active sub-child'
                                            title='Variety-sweepstake'
                                        >
                                            <span>Variety Points</span>
                                        </Link>
                                        <ul className='sub-menu'>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-variety-non-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>Non-National</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-variety-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>National</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to='/'
                                            className='has-arrow  m-0 gr-active sub-child'
                                            title='Group-sweepstake'
                                        >
                                            <span>Group Points</span>
                                        </Link>
                                        <ul className='sub-menu'>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-group-non-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>Non-National</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-group-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>National</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to='/'
                                            className='has-arrow waves-effect m-0 gr-active sub-child'
                                            title='Breed-sweepstake'
                                        >
                                            <span>Breed Points</span>
                                        </Link>
                                        <ul className='sub-menu'>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-breed-non-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>Non-National</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-breed-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>National</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to='/'
                                            className='has-arrow waves-effect m-0 gr-active sub-child'
                                            title='Other-sweepstake'
                                        >
                                            <span>Other Points</span>
                                        </Link>
                                        <ul className='sub-menu'>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-other-non-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>Non-National</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/dashboard/sweepstake-other-national'
                                                    className='waves-effect m-0'
                                                >
                                                    <span>National</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to='/dashboard/show-setup' className='waves-effect m-0'>
                                    <i className='bx bx-brightness' />
                                    <span>Show Setup</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to='/dashboard/members' className='waves-effect m-0'>
                                    <span>Members</span>
                                </Link>
                            </li> */}
                        </ul>
                    </li>

                    {/* Sanction */}

                    <li>
                        <Link to='/#' className='has-arrow  waves-effect m-0' title='Sanction'>
                            {/* <i class='bx bxs-credit-card-front'></i> */}
                            <i className='bx bx-credit-card fs-box' />
                            <span>Sanctions</span>
                        </Link>
                        <ul className='sub-menu member-sub'>
                            <li>
                                <Link
                                    to='/dashboard/sanction-id-configuration'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx bxs-id-card' />
                                    <span>ID Configuration</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/show-classification'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-sitemap' />
                                    <span>Show Classification</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/sanction-plans'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-dollar' />
                                    <span>Sanction Plans</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/sanction-list'
                                    className='waves-effect m-0 gr-active '
                                >
                                    <i className='bx bx-list-ol' />
                                    <span>Sanction List</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/sanction-letter-template'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-file' />
                                    <span>Sanction Template</span>
                                </Link>
                            </li>

                            {/* <li>
                                <Link
                                    to='/dashboard/sanction-card-template'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-news' />
                                    <span>Sanction Card Template</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link
                                    to='/dashboard/sanction_processing_fees'
                                    className='waves-effect m-0 gr-active'
                                >
                                    <i className='bx bx-money' />
                                    <span>Sanction Processing Fees</span>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to='/'
                                    className='has-arrow waves-effect m-0 sub-child'
                                    title='Sanction Reminder Setup'
                                >
                                    <i className='bx bx-alarm' />
                                    <span>Sanction Reminder </span>
                                </Link>
                                <ul className='sub-menu'>
                                    <li className=''>
                                        <Link
                                            to='/dashboard/sanction-reminder-setup'
                                            className='m-0 gr-active sub-child'
                                            title='Reminder Setup'
                                        >
                                            <span>Reminder Setup</span>
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link
                                            to='/dashboard/sanction-reminder-template'
                                            className='m-0 gr-active sub-child'
                                            title='Reminder Template'
                                        >
                                            <span>Reminder Template</span>
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link
                                            to='/dashboard/sanction-reminder-log'
                                            className='m-0 gr-active sub-child'
                                            title='Reminder log'
                                        >
                                            <span>Reminder Log</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link
                                    to='/dashboard/sanction-revenue-breakdown'
                                    className='waves-effect m-0 gr-active'
                                >
                                    {' '}
                                    <i className='bx bx-money' />
                                    <span>Revenue Breakdown</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    {/*  */}
                    <li>
                        <Link to='/dashboard/clerk' className='waves-effect m-0'>
                            <i className='fas fa-briefcase' />
                            <span>Clerk</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </SimpleBar>
    )
}

export default ClubSidebarContent