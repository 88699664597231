import { createAsyncThunk } from '@reduxjs/toolkit'
import { paginationData } from '../Pagination/reducers'
import * as service from './SanctionRevenueBreakdown.services'
// import { toast } from 'react-toastify'
import _ from 'lodash'
import { toast } from 'react-toastify'

export const SanctionRevenueBreakDown = createAsyncThunk(
    '@Sanction/revenue-breakdown',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await service.SanctionRevenueBreakDownApi(data)

            if (Number(data?.params?._limit || 0) > 1) {
                // Pagination Section
                const limit = data?.params?._limit || 10
                const TotalPage = Math.ceil(res.totalRecords / limit) || 1
                const paginationObj = {
                    page: data?.params?._page || 1,
                    totalPage: TotalPage || 1,
                    totalRecords: res?.totalRecords || 1
                }
                dispatch(paginationData(paginationObj))
            }

            return res
        } catch (err) {
            if (err?.message) {
                toast.error(err.message)
            }
            if (Number(data?.params?._limit || 0) > 1) {
                const paginationObj = {
                    page: 1,
                    totalPage: 1,
                    totalRecords: 1
                }
                dispatch(paginationData(paginationObj))
            }
            if (!err.response) {
                throw err
            }
            // if (err?.response?.data) {
            //     toast.error(err?.response?.data)
            // }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionMemberListRevenueBreakDown = createAsyncThunk(
    '@Sanction/revenue-breakdown-member-list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionMemberListRevenueBreakDownApi(data)
            const finalResult = _.orderBy(
                _.map(res?.data, (o) => ({
                    ...o,
                    label: `${o.last_name} ${o.first_name}`,
                    value: o.user_id
                })),
                [(o) => _.lowerCase(o?.label)],
                ['asc']
            )

            return finalResult
        } catch (err) {
            if (!err.response) {
                throw err
            }
            // if (err.response.data) {
            //     toast.error(err.response.data)
            // }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionPlanListRevenueBreakDown = createAsyncThunk(
    '@Sanction/revenue-breakdown-plan-list',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionPlanListRevenueBreakDownApi(data)
            
            const finalResult = _.orderBy(
                _.map(res?.data, (o) => ({
                    ...o,
                    label: o.title,
                    value: o.sanction_plan_id
                })),
                [(o) => _.lowerCase(o?.label)],
                ['asc']
            )

            return finalResult
        } catch (err) {
            if (!err.response) {
                throw err
            }
            // if (err.response.data) {
            //     toast.error(err.response.data)
            // }
            return rejectWithValue(err.response.data)
        }
    }
)

export const SanctionRevenueBreakDownGrandTotal = createAsyncThunk(
    '@Revenue-break-down/GrandTotal',
    async (data, { rejectWithValue }) => {
        try {
            const res = await service.SanctionRevenueBreakDownGrandTotalApi(data)
            const finalRes = {
                grand_total: _.sumBy(res?.data, (o) => Number(o.grand_total || 0.0)).toFixed(2),
                connected_accnt_amt: _.sumBy(res?.data, (o) =>
                    Number(o?.connected_accnt_amt || 0.0)
                ).toFixed(2),
                master_accnt_amt: _.sumBy(res?.data, (o) =>
                    Number(o?.master_accnt_amt || 0.0)
                ).toFixed(2)
            }
            return finalRes
        } catch (err) {
            // if (err.message) {
            //     toast.error(err.message)
            // } else
            if (!err.response) {
                throw err
            }
            // if (err.response.data) {
            //     toast.error(err.response.data)
            // }
            return rejectWithValue(err.response.data)
        }
    }
)
