import * as API from 'src/helpers/api_helper'
import * as URL from 'src/helpers/url_helper'

export const NewsLetterCreateApi = (ID, data) =>
    API.post(`${URL.NEWS_LETTER_CREATE_URL}/${ID}`, data)
export const NewsLetterUpdateApi = (ID, clubId, data) =>
    API.patch(`${URL.NEWS_LETTER_UPDATE_URL}/${ID}/${clubId}`, data)
export const NewsLetterListApi = (ID, data) => API.get(`${URL.NEWS_LETTER_LIST_URL}/${ID}`, data)
export const NewsLetterGetOneApi = (ID, data) =>
    API.get(`${URL.NEWS_LETTER_GET_ONE_URL}/${ID}`, data)
