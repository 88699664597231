import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
//

import Login from './Auth/reducer'
import ClubPlans from './ClubPlans/reducer'
import Pagination from './Pagination/reducers'
import Modal from './Modal/reducer'
import MemberPlanHistory from './MemberPlanHistory/reducer'
import MemberIDConfiguration from './MemberIDConfiguration/reducer'
import MembershipPlans from './MembershipPlans/reducer'
import MembershipList from './MembershipList/reducer'
import MemberShipCardTemplate from './MemberShipCardTemplate/reducer'
import MemberShipRenewLetterTemplate from './MemberShipRenewLetterTemplate/reducer'
import MembershipProcessingFees from './MembershipProcessingFees/reducer'
import AgeClassification from './AgeClassification/reducer'
import Country from './Country/reducer'
import State from './State/reducer'
import LetterTemplate from './LetterTemplate/reducer'
import ClubUser from './ClubUser/reducer'
import ClubSubUser from './ClubSubUser/reducer'
import MemberShipShoppingCart from './MemberShipShoppingCart/reducer'
import Sweepstake from './Sweepstake/reducer'
import SweepstakeMember from './SweepstakeMember/reducer'
import SweepstakeShowSetup from './SweepstakeShowSetup/reducer'
import Setting from './Setting/reducer'
import NationalControlSheet from './NationalControlSheet/reducer'
import StateReport from './StateControlSheet/reducer'
import Contest from './Contest/reducer'
import MemberShipReminder from './MemberShipReminder/reducer'
import SanctionIDConfiguration from './SanctionIDConfiguration/reducer'
import SanctionShowClassification from './SanctionShowClassification/reducer'
import SanctionLetterTemplate from './SanctionLetterTemplate/reducer'
import SanctionCardTemplate from './SanctionCardTemplate/reducer'
import SanctionProcessingFees from './SanctionProcessingFees/reducer'
import SanctionReminder from './SanctionReminder/reducer'
import SanctionPlans from './SanctionPlans/reducer'
import ClubSanctionShoppingCart from './ClubSanctionShoppingCart/reducer'
import AdminSanctionProcessingFees from './AdminSanctionProcessingFees/reducer'
import SanctionPurchaseList from './SanctionPurchaseList/reducer'
import Clerk from './Clerk/reducer'
import NewsLetter from './NewsLetter/reducer'
import MembershipRevenueBreakdown from './MembershipRevenueBreakdown/reducer'
import SanctionRevenueBreakdown from './SanctionRevenueBreakdown/reducer'

const rootReducer = combineReducers({
    // public
    Login,
    loadingBar: loadingBarReducer,

    // A-Z
    AdminSanctionProcessingFees,
    Country,
    Contest,
    Clerk,
    ClubPlans,
    ClubSubUser,
    ClubUser,
    ClubSanctionShoppingCart,
    Layout,
    LetterTemplate,
    AgeClassification,
    Modal,

    // Membership
    MemberPlanHistory,
    MemberIDConfiguration,
    MembershipPlans,
    MemberShipShoppingCart,
    MembershipList,
    MemberShipCardTemplate,
    MemberShipRenewLetterTemplate,
    MembershipRevenueBreakdown,
    MemberShipReminder,
    MembershipProcessingFees,
    NationalControlSheet,
    NewsLetter,
    Pagination,
    //
    // Sanction
    SanctionCardTemplate,
    SanctionIDConfiguration,
    SanctionLetterTemplate,
    SanctionPlans,
    SanctionProcessingFees,
    SanctionReminder,
    SanctionShowClassification,
    SanctionPurchaseList,
    SanctionRevenueBreakdown,

    //
    Setting,
    State,
    StateReport,
    SweepstakeMember,
    SweepstakeShowSetup,
    Sweepstake
})

export default rootReducer
