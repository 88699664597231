import { createSlice } from '@reduxjs/toolkit'
import {
    SanctionIDConfigurationList,
    SanctionIDConfigurationCreate,
    SanctionIDConfigurationUpdate
} from './thunk'
import _ from 'lodash'
// import { toast } from 'react-toastify'

export const IDConfigurationForm = {
    sanction_id_config_id: '',
    no_of_chars: 1,
    step: 'up',
    // is_add_char: false,
    // char_position: 'front',
    generation_type: 'numeric', //
    start_no: ['0'],
    end_no: ['0']
}
export const initialState = {
    form: IDConfigurationForm,
    loading: true,
    pageLoading: true
}

const SanctionIdConfiguration = createSlice({
    name: 'Sanction/IDConfiguration',
    initialState,
    reducers: {
        setPageLoader: (state) => {
            state.pageLoading = !state.pageLoading
        }
    },
    extraReducers: (builder) => {
        // Listing
        builder.addCase(SanctionIDConfigurationList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(SanctionIDConfigurationList.fulfilled, (state, action) => {
            const { data } = action.payload
            let formData = _.first(data)
            if (formData) {
                formData = {
                    ...formData,
                    // is_add_char: formData.is_add_char === 'Y' ? true : false,
                    end_no: formData.end_no.split('-'),
                    start_no: formData.start_no.split('-'),
                    // char_position: formData?.char_position,
                    step: formData?.step || '',
                    generation_type: formData?.generation_type || ''
                }
            }

            const finalData = _.pick(formData, Object.keys(IDConfigurationForm))

            state.form = formData ? finalData : IDConfigurationForm
            state.loading = false
        })
        builder.addCase(SanctionIDConfigurationList.rejected, (state) => {
            // const { payload } = action
            // toast.error(payload)
            state.loading = false
        })

        // Create
        builder.addCase(SanctionIDConfigurationCreate.pending, (state) => {
            state.loading = false
            // state.pageLoading = true
        })
        builder.addCase(SanctionIDConfigurationCreate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(SanctionIDConfigurationCreate.rejected, (state) => {
            state.loading = false
        })

        // Update
        builder.addCase(SanctionIDConfigurationUpdate.pending, (state) => {
            state.loading = false
        })
        builder.addCase(SanctionIDConfigurationUpdate.fulfilled, (state) => {
            state.pageLoading = !state.pageLoading
        })
        builder.addCase(SanctionIDConfigurationUpdate.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { setPageLoader } = SanctionIdConfiguration.actions
export default SanctionIdConfiguration.reducer
